import React, { Component } from "react";
import Logo from "../../assets/images/Sentiment_Logo_White.png";
import { Button, Col, Row, Typography, Menu, Drawer, Icon } from "antd";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

const { Text } = Typography;

class RegisterHeader2 extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      isDesktop: false,
    };
    this.updatePredicate = this.updatePredicate.bind(this);
  }

  componentDidMount() {
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    this.setState({ isDesktop: window.innerWidth > 991.98 });
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    const isDesktop = this.state.isDesktop;
    return (
      <>
        {isDesktop ? (
          <Row
            className="bg_app_color"
            type="flex"
            justify="space-between"
            align="middle"
            style={{ padding: "20px" }}
          >
            <Col
              sm={{ span: 22, push: 0 }}
              md={{ span: 22, push: 2 }}
              lg={{ span: 11, push: 1 }}
              className="cover_logo"
            >
              <Link to="/" className="text_semibold text_white">
                <img
                  src={Logo}
                  style={{
                    height: "45px",
                  }}
                  alt="logo"
                />
              </Link>
            </Col>
            <Col
              sm={{ span: 22, pull: 0 }}
              md={{ span: 22, pull: 2 }}
              lg={{ span: 11, pull: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                margin: "5px 0",
              }}
            >
              <div>
                <Button type="link" size="large">
                  <Link
                    to="/contact-us"
                    className={`text_semibold ${
                      this.props.location.pathname === "/contact-us"
                        ? "text_app_color_light"
                        : "text_white"
                    }`}
                  >
                    CONTACT US
                  </Link>
                </Button>
                <Button type="link" size="large">
                  <Link
                    to="/faqs"
                    className={`text_semibold ${
                      this.props.location.pathname === "/faqs"
                        ? "text_app_color_light"
                        : "text_white"
                    }`}
                  >
                    FAQ'S
                  </Link>
                </Button>
                <Button
                  className="cover_button_outline"
                  type="primary"
                  size="large"
                  style={{ margin: "5px" }}
                >
                  <Link to="/sign_in" className="text_semibold text_white">
                    LOG IN
                  </Link>
                </Button>
                <Button
                  className="cover_button"
                  type="primary"
                  size="large"
                  style={{ margin: "5px" }}
                >
                  <Link to="/register" className="text_semibold">
                    REGISTER
                  </Link>
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={24} className="bg_app_color">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ padding: "1em" }}>
                  <Link to="/" className="text_semibold text_white">
                    <img
                      src={Logo}
                      style={{
                        height: "32px",
                      }}
                      alt="logo"
                    />
                  </Link>
                </div>
                <Button
                  type="link"
                  onClick={this.showDrawer}
                  style={{ float: "right" }}
                >
                  <Icon type="menu" className="text_white" />
                </Button>
              </div>

              <Drawer
                placement="left"
                closable={false}
                onClose={this.onClose}
                visible={this.state.visible}
                bodyStyle={{ padding: 0 }}
              >
                <Menu
                  onClick={this.handleClick}
                  style={{ width: "auto" }}
                  selectedKeys={[this.props.location.pathname]}
                  mode="inline"
                >
                  <Menu.Item key="/contact-us">
                    <Link to="/contact-us" className="text_semibold">
                      CONTACT US
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/faqs">
                    <Link to="/faqs" className="text_semibold">
                      FAQ'S
                    </Link>
                  </Menu.Item>
                  <Button
                    className="cover_button_outline"
                    type="primary"
                    size="large"
                    style={{ margin: "5px" }}
                  >
                    <Link to="/sign_in" className="text_semibold ">
                      LOG IN
                    </Link>
                  </Button>
                  <Button
                    className="cover_button"
                    type="primary"
                    size="large"
                    style={{ margin: "5px" }}
                  >
                    <Link to="/register" className="text_semibold">
                      REGISTER
                    </Link>
                  </Button>
                </Menu>
              </Drawer>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default withRouter(RegisterHeader2);
